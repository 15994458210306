import React, { useEffect } from "react";
import MainLayout from "../layouts/MainLayout";
import Breadcrumb from "../components/common/breadcrumb";
import Seo from "../components/common/SEO";
import { encode } from "../utils";
import Form from "./form";

function ContactOne({ pageContext }) {
  const { title, content, seoMetaData } = pageContext;

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  let index = content.indexOf("{{form}}");
  // 8 is lenght of {{form}}
  let content1 = content.slice(0, index);
  let content2 = content.slice(index + 8);

  return (
    <MainLayout>
      <Seo title={title} page={{ title, seoMetaData }} />
      <div className="main">
        <Breadcrumb title="Contact" />
        <div className="container">
          <div
            className="page-header page-header-big text-center"
            style={{
              backgroundImage: `url(/assets/images/contact-header-bg.jpg)`,
            }}
          >
            <h1 className="page-title text-white">
              Contact us
              <span className="text-white">keep in touch with us</span>
            </h1>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div
              dangerouslySetInnerHTML={{
                __html: `${content1}`,
              }}
            />
            <Form encode={encode} />
            <div
              dangerouslySetInnerHTML={{
                __html: `${content2}`,
              }}
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default React.memo(ContactOne);
