import React, { useState } from "react";
import Recaptcha from "react-recaptcha";

const Form = () => {
  const [state, setState] = useState({
    status: "init",
    formData: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      message: "",
      subject: "",
    },
  });
  const [recaptcha,setRecaptcha]=useState(null)
  const handleChange = (e) => {
    setState({
      ...state,
      formData: { ...state.formData, [e.target.name]: e.target.value },
    });
  };

  const handleSubmit = (e) => {

    e.preventDefault();
    let url = `${process.env.GATSBY_API_BASE_URL}/api/v1/marketplace/forms`;
    setState({ status: "submitting",...state });
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.GATSBY_API_ACCESS_TOKEN}`,
      },
      body: JSON.stringify({
        ...state.formData,
        contactRequest: {
          isContact: true,
          message: state.formData.message,
          subject: state.formData.subject,
        },
      }),
    })
      .then((res) => {
        setState({ status: "success" });
      })
      .catch((err) => {
        setState({ status: "failed" });
        console.log(err);
      });

    e.preventDefault();
  };
  return (
    <div className="touch-container row justify-content-center">
      <div className="col-md-9 col-lg-7">
        {state.status === "init" && (
          <form
            action="#"
            className="contact-form mb-2"
            name="contact"
            method="POST"
            aria-label="contact form"
            data-netlify="true"
            netlify-honeypot="bot-field"
            data-netlify-recaptcha="true"
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-sm-4">
                <label htmlFor="cname" className="sr-only">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="cname"
                  name="firstName"
                  placeholder="Enter your first name"
                  required
                  value={state.formData.firstName}
                  onChange={handleChange}
                />
              </div>

              <div className="col-sm-4">
                <label htmlFor="clastName" className="sr-only">
                  Last name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="clastName"
                  name="lastName"
                  placeholder="Enter your last name"
                  required
                  value={state.formData.lastName}
                  onChange={handleChange}
                />
              </div>

              <div className="col-sm-4">
                <label htmlFor="cphone" className="sr-only">
                  Phone
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="cphone"
                  name="phone"
                  placeholder="Enter your number"
                  required
                  value={state.formData.phone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <label htmlFor="cemail" className="sr-only">
              Subject
            </label>
            <input
              type="email"
              className="form-control"
              id="cemail"
              name="email"
              placeholder="Email"
              required
              value={state.formData.email}
              onChange={handleChange}
            />
            <label htmlFor="csubject" className="sr-only">
              Subject
            </label>
            <input
              type="text"
              className="form-control"
              rows="6"
              placeholder="Write Your Subject"
              id="csubject"
              name="subject"
              value={state.formData.subject}
              onChange={handleChange}
            />
            <label htmlFor="cmessage" className="sr-only">
              Message
            </label>
            <textarea
              className="form-control"
              rows="4"
              cols="30"
              placeholder="Write Your Message"
              id="cmessage"
              name="message"
              value={state.formData.message}
              onChange={handleChange}
            ></textarea>
            <div className="col-md-12 mb-4">
              <Recaptcha
                sitekey={`${process.env.GATSBY_SITE_RECAPTCHA_KEY}`}
                render="explicit"
                // onloadCallback={() => {}}
                verifyCallback={(res) => setRecaptcha(res )}
              />
            </div>
            <div className="text-center">
              <button
                type="submit"
                disabled={
                  state.status === "submitting" ||
                  recaptcha === undefined
                }
                className="btn btn-outline-primary-2 btn-minwidth-sm"
              >
                <span style={{color: "#333333"}}>SUBMIT</span>
                <i className="icon-long-arrow-right"></i>
              </button>
            </div>
          </form>
        )}
        {state.status === "success" && (
          <div className="success-text">
            <i className="fa fa-check-circle" aria-hidden="true"></i>
            <h2>thank you</h2>
            <p aria-live="true">Your response has been received successfully.</p>
          </div>
        )}

        {state.status === "failed" && (
          <div className="success-text">
            <i
              className="fa fa-times-circle"
              aria-hidden="true"
              style={{ color: "#ff4c3b" }}
            ></i>
            <h2>Oops!</h2>
            <p aria-live="true">
              Something went wrong while submitting the response. Please refresh
              the page and try again.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Form;
